@use "../abstracts" as *;

/* ttl
********************************************** */
/* --- page_ttl --- */
.page_ttl {
  position: relative;
  z-index: 10;
  overflow: hidden;
  color: #fff;
  background-color: #002F56;
}
.page_ttl--bg-wrap {
  height: 500px;
  @include mq(sp) {
    height: 120px;
  }
}
.page_ttl .section_pdg {
  @include mq(sp) {
    padding-top: 65px;
    padding-bottom: 35px;
  }
}
.page_ttl--bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  background-size: cover;
  background-position: center;
}
.page_ttl-en {
  display: block;
  text-transform: uppercase;
  font-family: var(--font-en, "Montserrat", sans-serif);
  font-size: rem(60);
  @include lh(44,60);
  font-weight: 600;
  @include mq(sp) {
    font-size: rem(30);
  }
}
.page_ttl-jp {
  font-size: rem(20);
  letter-spacing: .05em;
  font-weight: 700;
  @include mq(sp) {
    font-size: rem(12);
  }
}

/* --- ttl --- */
.ttl-01,
.ttl-02,
.ttl-03 {
  letter-spacing: .05em;
}
.ttl-01 {
  font-size: rem(60);
  @include lh(44,60);
  margin-bottom: rem(48);
  font-weight: 600;
  @include mq(sp) {
    font-size: rem(36);
    margin-bottom: rem(32);
  }

  .sub {
    display: block;
    font-size: rem(20);
    letter-spacing: .05em;
    font-weight: 700;
    @include mq(sp) {
      font-size: rem(16);
    }
  }
}
.ttl-02 {
  font-size: rem(32);
  @include lh(32,46);
  margin-bottom: rem(48);
  @include mq(sp) {
    font-size: rem(24);
    margin-bottom: rem(28);
  }

  .sub {
    display: block;
    font-size: rem(14);
    letter-spacing: .05em;
    font-weight: 700;
    margin-top: rem(16);
    @include mq(sp) {
      font-size: rem(12);
    }
  }
}
.ttl-03 {
  margin-bottom: rem(24);
  font-size: rem(24);
  @include mq(sp) {
    margin-bottom: rem(12);
    font-size: rem(20);
  }
}
.lps_parts--img_text .ttl-03 {
  font-size: rem(32);
  @include mq(sp) {
    font-size: rem(24);
  }
}
.lps_sec .ttl-01,
.lps_sec .ttl-02,
.lps_sec .ttl-03 {
  position: relative;
  z-index: 1;
}
.lps_sec .ttl-01,
.ttl-01.jp {
  font-size: rem(40);
  @include mq(sp) {
    font-size: rem(28);
  }
}
.lps_sec .ttl-01::after {
  content: "";
  display: block;
  width: 2rem;
  border-top: solid 2px #B1C5DD;
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
}
.lps_sec .ttl-03:not(:last-child) {
  margin-bottom: 1em;
  @include mq(sp) {
    margin-bottom: .75em;
  }
}
.ttl-04 {
  font-size: 1.5rem; /* 24px */
  @include mq(sp) {
    font-size: rem(24);
  }
}
.lps_sec .column-3 .ttl-03,
.lps_sec .column-4 .ttl-03 {
  font-size: 1.5rem; /* 24px */
  @include mq(sp) {
    font-size: rem(24);
  }
}
