@use "sass:map";

// SASS用の基本設定変数

// デザイン上の幅
$designWidth: 1920px;

// 色
$clr-main: #1D7FB7;

// font-families
$font-primary: 'Montserrat', 'Noto Sans JP', sans-serif;  
$font-en: 'Montserrat', sans-serif;

// CSS変数
:root {
  // typography
  --ltr-space-default: .05em;
  --line-height-default: 2;
  --line-height-hdr: 1.5;
  --line-height-none: 1;

  // font-families
  --font-primary: 'Montserrat', 'Noto Sans JP', sans-serif;  
  --font-en: 'Montserrat', sans-serif;

  // font-sizes
  --fz-primary: 1.125rem;
  --fz-half: .5rem;



  // text colors
  --clr-main: #002F56;
  --clr-txt-sub: #002F56;
  --clr-body: #000;
  --clr-blue: #B1C5DD;
  --clr-dark-blue: #002F56;
  --clr-link: #000;
  --clr-wht: #fff;
  --clr-blk: #000;

  // background colors
  --bg-main: #1D7FB7;
  --bg-sub: #eee;
  --bg-wht: #fff;
  --bg-off_wht: #F7F7F7;
  --bg-blk: #000;

  //button colors
  --btn-bg-main: var(--clr-wht);
  --btn-bg-main-hvr: var(--clr-body);
  --btn-clr-main: var(--clr-body);
  --btn-clr-main-hvr: var(--clr-wht);

  --btn-clr-wht: var(--clr-wht);
  --btn-clr-wht-hvr: var(--clr-body);
  --btn-bg-wht-hvr: var(--clr-body);


  // border colors
  --brd-main: #1D7FB7;

  // animation
  --transit-default: all 0.4s ease-out;


  // formy settings

}

// SASS Maps

$z-index: (
  "header" : 9999,
  "top": 9990,
  "aside" : 75,
  "middle" : 50,
  "main" : 1,
  "base" : 0,
  "under": -1,
  "deep": -100,
  "bottom": -999,
);

$em-breakpoints: (
  xl: 160, // 2560px
  lg: 120, // 1920px
  pc: 85.375, // 1366px
  med: 67.5, // 1080px
  sp: 47.9375, // 767px
  sm: 40, // 640px
  xs: 34.375, // 500px
  se: 21.25, // 350px
);
$px-breakpoints: (
  xl: 2560,
  lg: 1920,
  pc: 1366,
  med: 1080,
  sp: 767,
  sm: 640,
  xs: 500,
  se: 350,
);

$colors: (
  'blue': (
    100: hsl(202, 73%, 82%),
    200: hsl(202, 73%, 72%),
    300: hsl(202, 73%, 62%),
    400: hsl(202, 73%, 52%),
    500: hsl(202, 73%, 42%),
    600: hsl(202, 73%, 32%),
  ),
  'red': (
    100: hsl(0, 100%, 80%),
    200: hsl(0, 100%, 70%),
    300: hsl(0, 100%, 60%),
    400: hsl(0, 100%, 50%),
    500: hsl(0, 100%, 40%),
    600: hsl(0, 100%, 30%),
  ),
  'green': (
    100: hsl(118, 100%, 80%),
    200: hsl(118, 100%, 70%),
    300: hsl(118, 100%, 60%),
    400: hsl(118, 100%, 50%),
    500: hsl(118, 100%, 40%),
    600: hsl(118, 100%, 30%),
  )
);