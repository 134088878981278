@use '../abstracts' as *;

/* lps_parts--img_text
********************************************** */
.lps_parts--img_text + .lps_parts--img_text { margin-top: 0; }
.lps_parts--img_text .img_text--child:only-child,
.lps_parts--img_text .lps_parts--child.bg-wh .img_text--child:only-child { width: 100%; }
.lps_parts--img_text .lps_parts--child .img_text--img:only-child .img {
  display: table;
  @include auto-margin;
}
.lps_parts--img_text .img_text--child .img-detail { height: 100%; }
.lps_parts--img_text .img_text--txt { padding: 5% 7%; }
.lps_parts--img_text.inner-lg .img_text--txt { padding: 5% vw(194); }
.lps_parts--img_text .img_text--txt--inner {
  width: 100%;
}
.lps_parts--img_text .text { justify-content: flex-start; }
.lps_parts--img_text .img_text--txt table { width: 100%; }
.lps_parts--img_text .inner-lg .img_text--img {
  position: absolute;
  top: 0;
}
.lps_parts--img_text .btn {
  text-align: right;
}
.lps_parts--img_text .inner-lg {
  max-width: none;
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--img {
  left: 0;
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--img {
  right: 0;
}
.lps_parts--img_text .inner-lg:not(.flex-reverse) .img_text--txt {
  margin-left: auto;
}
.lps_parts--img_text .inner-lg.flex-reverse .img_text--txt {
  margin-right: auto;
}

@include mq(sp, min, ps) {
  .lps_parts--img_text .inner-lg .img_text--img {
    height: 100%;
  }
  .lps_parts--img_text .inner-lg .img_text--txt {
    display: flex;
    align-items: center;
    min-height: 607.64px;
  }
  .lps_parts--img_text .inner-lg.bg-wh .img_text--txt {
    padding: 6%;
  }
}
@include mq(sp) {
  .lps_parts--img_text .img_text--txt,
  .lps_parts--img_text.inner-lg .img_text--txt { padding: 10% 3%; }
  .lps_parts--img_text .bg-wh .img_text--txt { padding: 10% 3%; }
  .lps_parts--img_text .btn { text-align: center; }
  .lps_parts--img_text .inner-lg .img_text--txt {
    margin-right: auto;
  }
  .lps_parts--img_text.bg-wh + .bg-wh {
    margin-top: 0;
  }
  .lps_parts--img_text .inner-lg .img_text--img {
    position: relative;
  }
  .lps_parts--img_text .inner-lg .img_text--child .img-detail {
    width: 100vw;
    margin-left: calc(50% - 50vw);
  }
}