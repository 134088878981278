@use '../abstracts' as *;

/* --- section_pdg --- */
.section_pdg {
  padding-top: 100px;
  padding-bottom: 100px;
  @include mq(sp) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
.section_pdg-sm {
  padding-top: 64px;
  padding-bottom: 64px;
  @include mq(sp) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
