@use '../abstracts' as *;

/* btn
********************************************** */
*:not(.flex) > .btn:not(:last-child) {
  margin-bottom: 8px;
}
.btn a,
.side--btn a {
  position: relative;
  z-index: 1;
  display: inline-block;
  padding: 12px 40px;
  width: 100%;
  max-width: 240px;
  color: var(--btn-clr-main, #fff);
  text-align: center;
  border: solid 1px;
  @include mq(sp) {
    max-width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  svg {
    fill: var(--btn-clr-main, #fff);
  }
}  
.flex > .btn a,
.flex.side--btn a { max-width: 100%; }
.btn.txt-ctr a {
  @include auto-margin;
}
.btn a:hover,
.side--btn a:hover {
  color: var(--btn-clr-main-hvr, #fff);
  background-color: var(--btn-bg-main-hvr, #015691);
  
  svg {
    fill: var(--btn-clr-main-hvr, #fff);
  }
}
.btn a:not([target="_blank"]):not([href$=".pdf"]):not([href$=".jpg"]):not([href$=".png"]),
.side--btn a{
  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 18px;
    margin: auto;
    display: block;
    @include rect(32);
    border: solid 1px var(--btn-clr-main, #fff);
    border-radius: 50%;
    transition: var(--transit-default, all .4s ease-out);
    background-image: url(../images/common/arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50% auto;
    @include mq(sp) {
      @include rect(24);
    }
  }
  &:hover::before {
    filter: invert(100%);
  }
}
.btn a[href^="#"]::before { 
  transform: rotate(90deg);
}
.btn.btn-next_page a {
  max-width: 100%;
  border-radius: 0;
  text-align: left;
  @include mq(sp) {
    padding-left: 16px;
  }
}

/* --- btn-wh --- */
.btn-wh a {
  color: var(--btn-clr-wht, #fff);
  &::before {
    filter: invert(100%);
  }
}
.btn-wh a svg {
  fill: var(--btn-clr-wht, #333);
}
.btn-wh a:hover {
  border-color: var(--btn-clr-wht-hvr, #fff);
}
@include mq(sp) {
  .flex > .btn:not(:last-child) {
    margin-bottom: 8px !important;
  }
}