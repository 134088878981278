@use '../abstracts' as *;

/* ##############################################################################

    HEADER

############################################################################## */

/* header
**************************************** */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 122px;
  z-index: z(header);
  transition: var(--transit-default, all 0.4s ease-out);
  @include mq(med) {
    height: 64px;
  }

  & > .inner {
    width: 100%;
    max-width: 100%;
    height: 100%;
    padding-left: vw(80);
    padding-right: vw(80);
    @include mq(1500) {
      padding-left: 1.5%;
      padding-right: 1.5%;
    }
    @include mq(med) {
      padding-left: 3%;
      padding-right: 0
    }
  }
}
.header:not(.header-sm) li:not(.gnav--contact) a {
  @include mq(med, min, ps) {
    color: var(--clr-wht, #fff);
  }
}
.header:not(.header-sm) .gnav--contact a {
  @include mq(med, min, ps) {
    border-color: transparent;
  }
}
.page-contact .header {
  position: absolute;
}
.header--logo a,
.footer--logo a {
  position: relative;
  display: block;
}
.header--logo a:hover,
.footer--logo a:hover {
  opacity: 0.7;
}

.header--logo .clr,
.header--logo .wh {
  display: block;
  transition: var(--transit-default, all 0.4s ease-out);

  img {
    width: 160px;
    @include mq(med) {
      width: auto;
      max-height: 40px;
    }
  }
}
.header--logo .clr {
  position: absolute;
  inset: 0;
}
.header:not(.header-sm) .header--logo {
  .clr {
    opacity: 0;
  }
  .wh {
    opacity: 1;
    @include mq(med, min, ps) {
      transform: translateY(12px);
    }
  }
}
.header.header-sm .header--logo {
  .clr {
    opacity: 1;
  }
  .wh {
    opacity: 0;
  }
}

.header-sm {
  background-color: var(--bg-wht, #fff);
  @include mq(med, min, ps) {
    height: 90px;
  }
}

/* gnav
********************************************** */
.gnav--menu li {
  padding-left: 1.5em;
  padding-right: 1.5em;
  font-size: rem(15);
}
.gnav--menu a {
  padding-bottom: 4px;
  border-bottom: solid 1px;
}
.gnav a {
  display: flex;
  @include center-flex;
}
.head_sub_nav--wrap {
  display: block;
  width: 100%;
  position: absolute;
  z-index: 100;
  transition: var(--transit-default, all 0.4s ease-out);
  opacity: 0;
  pointer-events: none;
}
.menu-item-has-children {
  position: relative;
}
.head_sub_nav li:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.head_sub_nav a {
  display: block;
  padding: 16px 24px;
}

/* ---CTA --- */
.cta_tel a {
  display: flex !important;
  justify-content: center;
  align-items: center;
  line-height: 1.4;
}
.cta_tel a > span {
  display: block;
}
.cta_tel .tel {
  font-size: 1.25rem; /* 20px */
}
.cta_tel .num {
  font-size: 1.5rem; /* 24px */
}
.cta_tel svg,
.cta_contact svg {
  margin-right: 1rem;
}
.gnav--tel svg {
  fill: #fff;
}
.cta_contact a {
  display: flex !important;
  justify-content: center;
  align-items: center;
  padding: 6px 34px;
}
.gnav--contact a {
  margin-left: 2rem;
  background-color: var(--btn-bg-main, #015691);
}

@include mq(med, min, ps) {
  .gnav,
  .gnav ul,
  .gnav li {
    height: 100%;
  }
  .gnav {
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-left: vw(40);
  }
  .gnav li {
    display: flex;
    @include center-flex;
  }
  .gnav_btn,
  .tel_btn {
    display: none;
  }
  .gnav--link {
    text-align: center;
  }
  .gnav--menu > li > .gnav--link[href="javascript:void(0);"]:hover,
  .gnav--menu > li:not(.current-menu-item) > .gnav--link {
    border-bottom-color: transparent;
  }
  .head_sub_nav a {
    background-color: #fff;
  }
  .gnav_sub_toggle {
    display: none;
  }
  .gnav--contact {
    opacity: 0;
  }
  .header-sm .gnav--contact {
    opacity: 1;
  }
  .gnav--contact a {
    border-radius: 0;
  }
  .header-sm .gnav--tel a svg {
    fill: var(--btn-clr-main, #333);
  }
  /* hover */
  .gnav--menu .menu-item-has-children:hover .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a:hover {
    opacity: 0.7;
  }
}
@include mq(1500) {
  .gnav--menu li {
    padding-left: 1em;
    padding-right: 1em;
    font-size: rem(14);
  }
}
@include mq(1260) {
  .gnav--menu li {
    padding-left: .75em;
    padding-right: .75em;
  }
  .gnav--menu li:first-child {
    display: none;
  }
  .cta_tel svg,
  .cta_contact svg {
    margin-right: .5rem;
  }
  .cta_tel .num {
    font-size: 1.25rem;
  }
  .cta_contact a {
    padding: 6px 20px;
  }
  .gnav--contact a {
    margin-left: 1rem;
  }
}
@include mq(med) {
  .gnav,
  .gnav_overlay {
    position: fixed;
    top: 64px;
    right: 0;
    height: calc(100% - 64px);
    opacity: 0;
    pointer-events: none;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav {
    width: 70%;
    margin: 0;
    padding: 24px 7%;
    overflow: scroll;
    background-color: #fff;
    z-index: 300;
    transform: translateX(100%);
    -webkit-overflow-scrolling: touch;
  }
  .gnav_overlay {
    width: 100%;
    background-color: rgba(0, 0, 0, .1);
  }
  .gnav.active,
  .gnav_overlay.active {
    opacity: 1;
    pointer-events: auto;
  }
  .gnav.active {
    transform: translateX(0);
  }
  .gnav--menu,
  .gnav--cta {
    display: block;
  }
  .gnav--menu li {
    position: relative;
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    font-size: 1rem;
  }
  .gnav--menu li:first-child {
    display: block;
  }
  .gnav--menu a {
    display: block;
    padding: 18.5px 16px;
  }
  .gnav--menu li:not(.menu-item-has-children) a::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    width: 7px;
    height: 7px;
    border-top: 1px solid var(--clr-body, #1d7fb7);
    border-right: 1px solid var(--clr-body, #1d7fb7);
    transform: rotate(45deg);
  }
  .head_sub_nav--wrap {
    position: relative;
    width: 100%;
    border-top: 1px dotted var(--clr-body, #1d7fb7);
    transition: none;
    opacity: 1;
    pointer-events: inherit;
    display: none;
  }
  .menu-item-has-children.active .head_sub_nav--wrap {
    opacity: 1;
    pointer-events: auto;
  }
  .head_sub_nav a {
    padding-left: 32px !important;
  }
  .gnav_sub_toggle {
    position: absolute;
    top: 0;
    right: -20px;
    width: 64px;
    height: 64px;
    transition: var(--transit-default, all 0.4s ease-out);
    color: var(--clr-body, #1d7fb7);
  }
  .gnav_sub_toggle::before,
  .gnav_sub_toggle::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 11px;
    height: 1px;
    border-bottom: 1px solid;
  }
  .gnav_sub_toggle::after {
    transform: rotate(90deg);
  }
  .gnav_sub_toggle.active {
    transform: rotate(180deg);
  }
  .head_sub_nav {
    position: relative;
  }
  .tel_btn,
  .gnav_btn {
    position: fixed;
    top: 0;
    width: 48px;
    height: 64px;
    z-index: 9999;
  }
  .tel_btn {
    right: 56px;
  }
  .tel_btn a {
    display: flex !important;
    width: 100%;
    height: 100%;
  }
  .tel_btn svg {
    width: 22px;
    fill: var(--clr-wht, #1d7fb7);
  }
  .header-sm .tel_btn svg {
    fill: var(--clr-body, #1d7fb7);
  }
  .gnav_btn {
    right: 8px;
    transition: var(--transit-default, all 0.4s ease-out);
  }
  .gnav_btn--lines {
    position: relative;
    width: 24px;
    height: 16px;
  }
  .gnav_btn--lines span {
    transition: var(--transit-default, all 0.4s ease-out);
    box-sizing: border-box;
  }
  .gnav_btn--lines span {
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: var(--clr-wht, #1d7fb7);
  }
  .header-sm .gnav_btn--lines span {
    background-color: var(--clr-body, #1d7fb7);
  }
  .gnav_btn--lines span:nth-of-type(1) {
    top: 0;
  }
  .gnav_btn--lines span:nth-of-type(2) {
    top: 7px;
  }
  .gnav_btn--lines span:nth-of-type(3) {
    bottom: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(1) {
    transform: translateY(7px) rotate(-40deg);
  }
  .active .gnav_btn--lines span:nth-of-type(2) {
    opacity: 0;
  }
  .active .gnav_btn--lines span:nth-of-type(3) {
    transform: translateY(-7px) rotate(40deg);
  }

  /* ---CTA --- */
  .gnav--cta li:not(:last-child) {
    margin-bottom: 20px;
  }
  .gnav--tel {
    margin-top: 32px;
    margin-left: 0;
    text-align: center;
  }
  .gnav--tel svg {
    fill: #000;
  }
  .cta_tel svg,
  .cta_contact svg {
    margin-right: 1rem;
  }
  .cta_tel .num {
    font-size: 1.5rem;
  }
  .gnav--contact {
    margin-top: 32px;
    margin-left: 0;
    font-size: 1.125rem;
  }
  .gnav--contact a {
    padding: 10px 16px;
    margin-left: auto;
    margin-right: auto;
  }
}
@include mq(sp) {
  .gnav--menu a {
    padding: 19px 16px;
  }
}
@include mq(350) {
  .cta_tel .num {
    font-size: 1.25rem;
  }
}

/* cookie
********************************************** */
#cookie-notice .cn-button:not(.cn-button-custom) {
  color: #fff;
}