@use '../abstracts/' as *;

/* home_about
********************************************** */
.home_about {
  padding-top: 164px;
  padding-bottom: 180px;
  @include mq(sp, min) {
    background-image: url(../images/home/about-img04.png);
    background-repeat: no-repeat;
    background-position: right 3.8% center;
  }
  @include mq(1450) {
    background-position: right center;
  }
  @include mq(sp) {
    padding-top: 82px;
    padding-bottom: 90px;
  }
}
.home_about .ttl-02 {
  margin-bottom: rem(32);
  font-size: rem(28);
  @include mq(sp) {
    margin-bottom: rem(24);
    font-size: rem(20);
  }
}
.home_about .txt {
  margin-bottom: rem(28);
  @include mq(sp) {
    margin-bottom: rem(20);

    br {
      display: none;
    }
  }

  &:last-of-type {
    margin-bottom: rem(48);
    @include mq(sp) {
      margin-bottom: rem(32);
    }
  }
}
.home_about .deco-1 {
  left: vw(200);
  bottom: -50px;
  @include mq(1450) {
    left: 0;
  }
  @include mq(sp) {
    bottom: -24px;
    width: 25%;
  }
}
.home_about .deco-2 {
  top: 20%;
  right: vw(68);
  @include mq(1450) {
    right: 0;
  }
  @include mq(1300) {
    width: 28%;
  }
  @include mq(sp) {
    top: 13%;
    right: 2%;
    width: 30%;
  }
}
.home_about .deco-3 {
  top: 68%;
  right: vw(114);
  @include mq(1450) {
    right: 0;
  }
  @include mq(1300) {
    width: 20%;
  }
  @include mq(sp) {
    display: none;
  }
}


/* home_movie
********************************************** */
.home_movie video {
  width: 100%;
  vertical-align: bottom;
}


/* home_feature
********************************************** */
.home_feature {
  position: relative;
  padding-top: 160px;
  padding-bottom: 78px;
  @include mq(sp) {
    padding-top: 80px;
    padding-bottom: 40px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 78px;
    z-index: -1;
    display: block;
    width: vw(1780);
    background-color: var(--bg-off_wht, #f7f7f7);
    @include mq(sp) {
      bottom: 64px;
      width: 95%;
    }
  }
}
.home_feature .ttl-01 {
  margin-bottom: rem(64);
  @include mq(sp) {
    margin-bottom: rem(32);
  }
}
.home_feature .imgarea {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
  width: vw(1394);
  height: 679px;
  @include mq(sp) {
    width: 95%;
    height: 10%;
  }
}
.home_feature .btn {
  position: relative;
  z-index: 10;
  @include mq(sp, min) {
    margin-right: vw(40);
  }
  @include mq(sp) {
    width: 55%;
    margin-left: auto;
  }
}
.home_feature .btn .en {
  margin-top: 1rem;
  margin-bottom: 4rem;
}

/* --- feature-list --- */
.feature-list {
  position: relative;
  z-index: 5;
  transform: translateX(3%);

  &::before {
    content: '';
    position: absolute;
    z-index: 5;
    top: 0;
    right: 0;
    bottom: -8px;
    display: block;
    width: 72%;
    background-color: var(--bg-off_wht, #f7f7f7);
    @include mq(sp) {
      width: 70%;
      bottom: -120px;
    }
  }
}
.feature-content {
  position: relative;
  z-index: 10;
  padding: 28px vw(80) 30px;
  margin-bottom: 64px;
  counter-increment: number;
  &:nth-child(4) {
    margin-left: auto;
  }
  @include mq(sp) {
    padding: 0 5%;
    &:last-child {
      margin-bottom: 48px;
    }
  }

  &::before {
    content: "";
    position: absolute;
    right: 0;
    transform: rotate(15deg);
    @include mq(1300) {
      transform: rotate(10deg);
    }
    @include mq(sp, min) {
      top: 0;
      bottom: 0;
      border-right: solid 1px;
    }
    @include mq(sp) {
      left: 0;
      bottom: -52px;
      border-top: solid 1px;
    }
  }
  @include mq(sp, min) {
    &:nth-child(3n)::before,
    &:last-child::before {
      display: none;
    }
  }
  @include mq(sp) {
    &:last-child::before {
      display: none;
    }
  }
}
.feature-content .num {
  margin-bottom: 1.5rem;
  @include mq(sp) {
    margin-bottom: 1rem;
  }

  &::before {
    content: counter(number, decimal-leading-zero);
    display: block;
    margin-bottom: .25rem;
    font-size: rem(60);
    font-weight: 600;
    letter-spacing: .05em;
    line-height: 1;
    @include mq(sp) {
      margin-bottom: 0;
      font-size: rem(36);
    }
  }
}
.feature-content .num .en {
  @include mq(sp) {
    font-size: rem(12);
  }

  &::after {
    content: '';
    display: inline-block;
    width: 2em;
    border-top: solid 1px;
    margin-left: .75em;
    transform: translateY(-4px);
    @include mq(sp) {
      margin-left: .5em;
    }
  }
}
.feature-content .ttl-03 {
  display: flex;
  align-items: center;
  min-height: 3em;
  margin-bottom: rem(16);
  @include mq(1320) {
    br {
      display: none;
    }
  }
  @include mq(sp) {
    br {
      display: block;
    }
  }
}
.feature-content .txt {
  @include lh(16,30)
}


/* home_possibility
********************************************** */
.home_possibility {
  position: relative;
  padding-top: 160px;
  @include mq(sp) {
    padding-top: 80px;
  }
}
.home_possibility .deco {
  top: 0;
  left: vw(123);
  z-index: 10;
  transform: translateY(-50%);
  @include mq(sp) {
    width: 58px;
  }
}
.home_possibility .ttl-01 {
  margin-bottom: rem(112);
  @include mq(sp) {
    margin-bottom: rem(56);
  }
}

/* --- possibility-content --- */
.possibility-content {
  position: relative;
}
.possibility-content .deco-1,
.possibility-content .deco-2 {
  top: vw(190);
  width: 86%;
  z-index: -1;
  @include mq(sp) {
    top: 105vw;
    width: 200%;
    max-width: 200%;
  }
}
.possibility-content .deco-1 {
  left: 0;
}
.possibility-content .deco-2 {
  right: 0;
  @include mq(sp, min) {
    top: vw(140);
  }
}
.possibility-content .txtarea {
  padding: vw(280) vw(190) 130px vw(210);
  @include mq(1400) {
    padding: vw(180) 5%;
  }
  @include mq(sp) {
    padding: 10% 8% 16%;
  }
}
.possibility-content:nth-of-type(2) .txtarea {
  padding: vw(180) vw(250) 100px vw(190);
  @include mq(1400) {
    padding: vw(180) 5%;
  }
  @include mq(sp) {
    padding: 10% 8% 24%;
  }
}


/* home_products
********************************************** */
.home_products {
  position: relative;
  padding-bottom: 40px;
  @include mq(sp) {
    padding-bottom: 20px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: -10;
    width: vw(1780);
    display: block;
    background-color: var(--bg-off_wht, #f7f7f7);
    @include mq(sp) {
      width: 96vw;
    }
  }
}
.home_products .inner {
  @include mq(sp) {
    width: 100%;
    overflow: hidden;
  }
}
.home_products .deco-1 {
  transform: rotate(200deg);
  margin-bottom: 90px;
  @include mq(sp) {
    width: 58px;
    margin-bottom: 44px;
  }
}
.home_products .deco-2 {
  bottom: 340px;
  left: 0;
  right: 0;
  z-index: -1;
  @include mq(sp) {
    bottom: 170px;
    max-width: 200%;
  }
}
.home_products .ttl-01 {
  margin-bottom: rem(80);
  @include mq(sp) {
    margin-bottom: rem(40);
  }
}

/* --- products-gallery --- */
.products-gallery {
  display: flex;
  justify-content: space-between;
  transform: translateY(-48px);
  margin-bottom: 56px;
  @include mq(sp) {
    transform: translateY(-24px);
    margin-bottom: 28px;
  }
}
.products-gallery li {
  max-width: vw(340);
  box-shadow: -11px 6px 29px rgba(4,4,4,.1);
  @include mq(sp) {
    max-width: 30%;

    &:first-child,
    &:last-child {
      display: none;
    }
  }
}

/* --- products-list --- */
.products-content {
  position: relative;
  display: flex;
  @include center-flex;
  padding: 12px vw(48) 30px;
  margin-bottom: 90px;
  @include mq(sp) {
    padding: 0 5% 10px;
    margin-bottom: 24px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border-right: solid 1px;
    transform: rotate(15deg);
    @include mq(1300) {
      transform: rotate(10deg);
    }
    @include mq(sp) {
      transform: rotate(5deg);
    }
  }
  @include mq(sp, min) {
    &:nth-child(3n)::before {
      display: none;
    }
  }
  @include mq(sp) {
    &:nth-child(2n)::before {
      display: none;
    }
  }
}
.products-content .link {
  display: block;
  @include mq(sp, min) {
    &:hover .img {
      opacity: 0;
    }
    &:hover .img-hover {
      opacity: 1;
    }
    &:hover .arrow {
      transform: translateX(.5rem) translateY(7px);
    }
  }
}
.products-content .imgarea {
  position: relative;
  display: flex;
  @include center-flex;
  height: 282px;
  margin-bottom: rem(8);
  @include mq(sp) {
    height: 140px;
  }
}
.products-content .imgarea img {
  transition: var(--transit-default, all 0.4s ease-out);
  @include mq(sp) {
    max-width: none;
    transform: scale(.4,.4);
  }
  @include mq(400) {
    transform: scale(.35,.35);
  }
}
.products-content .imgarea .img-hover {
  position: absolute;
  inset: 0;
  margin: auto;
  opacity: 0;
  @include mq(sp) {
    display: none;
  }
}
.products-content .name {
  font-size: rem(24);
  letter-spacing: .05em;
  font-weight: 700;
  @include mq(sp) {
    font-size: rem(16);
  }
}
.products-content .link .arrow {
  display: inline-block;
  @include rect(32);
  margin-left: .5rem;
  border: solid 1px;
  border-radius: 50%;
  background-image: url(../images/common/arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50% auto;
  transform: translateY(7px);
  transition: var(--transit-default, all .4s ease-out);
  @include mq(sp) {
    @include rect(20);
    margin-left: .25rem;
    transform: translateY(5px);
  }
}
.products-content.more {
  align-items: flex-end;
}
.products-content.more > div {
  width: 240px;
  max-width: 100%;
  @include mq(sp) {
  }
}
.products-content.more .en {
  display: block;
  margin-left: 0;
  margin-bottom: 1em;
  text-align: left;
}
.products-content.more .btn a {
  max-width: none;
  @include mq(sp) {
    padding-left: 32px;
    padding-right: 32px;
    &::before {
      right: 8px !important;
    }
  }
}


/* home_trial
********************************************** */
.home_trial {
  display: flex;
  align-items: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-color: #292830;
  @include mq(sp, min) {
    min-height: 870px;
    background-image: url(../images/home/trial-img01.jpg);
  }
  @include mq(sp) {
    background-image: url(../images/home/trial-img01-sp.jpg);
  }
}
.home_trial .txtarea {
  width: 50%;
  padding: 100px vw(64) 120px vw(212);
  @include mq(sp) {
    width: 100%;
    padding: 80px 7%;
  }
}
.home_trial .ttl-01 {
  margin-bottom: 6.5rem;
  @include mq(sp) {
    margin-bottom: 3.5rem;
  }
}
.home_trial .ttl-03 {
  @include mq(sp) {
    margin-bottom: 1.5rem;
  }
}
.home_trial .txtarea p {
  margin-bottom: rem(56);

  @include mq(sp) {
    margin-bottom: rem(40);
  }
}


/* home_cases
********************************************** */
.home_cases {
  position: relative;
  padding-top: 180px;
  overflow: hidden;
  @include mq(sp) {
    padding-top: 80px;
  }
}
.home_cases .ttl-01 {
  margin-bottom: rem(110);
  @include mq(sp) {
    margin-bottom: rem(54);
  }
}
.home_cases .deco-1 {
  top: 65%;
  left: -200%;
  right: -200%;
  z-index: -1;
  margin: auto;
  @include mq(sp) {
    max-width: 200%;
  }
}

/* --- cases-list --- */
.cases-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: vw(48);
  row-gap: vw(48);
  @include mq(sp) {
    grid-template-columns: 1fr 1fr;
    column-gap: spvw(12);
    row-gap: spvw(12);
  }
}
.cases-list li {
  display: flex;
  @include center-flex;
  aspect-ratio: 340/251;
  min-height: 90px;
  background-color: var(--btn-bg-main-hvr, #015691);
  box-shadow: -11px 6px 29px rgba(4,4,4,.15);
}
.cases-list .img {
  transition: var(--transit-default, all .4s ease-out);
}
.cases-list .txt {
  position: relative;
  z-index: 10;
  font-size: rem(24);
  letter-spacing: .05;
  line-height: 1;
  font-weight: 700;
  @include mq(sp) {
    font-size: rem(18);
  }
}


/* home_news
********************************************** */
.home_news {
  padding-top: 140px;
  padding-bottom: 140px;
  @include mq(sp) {
    padding-top: 64px;
    padding-bottom: 64px;
  }
}
.home_news .deco {
  display: block;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 138px;
  transform: rotate(200deg);
  @include mq(sp) {
    width: 58px;
    margin-bottom: 64px;
  }
}
.home_news .ttl-01 {
  margin-bottom: 1rem;
  @include mq(sp) {
    margin-bottom: .5rem;
  }
}
.home_news .more {
  &::after {
    content: "";
    display: inline-block;
    margin: auto;
    width: .55em;
    height: .55em;
    border-bottom: 2px solid;
    border-right: 2px solid;
    margin-left: 1em;
    transform: translateY(-1px) rotate(-45deg);
    transition: all 0.2s ease-out;
}
}
.home_news .news_list {
  flex: 1;
  @include mq(sp, min) {
    margin-left: vw(147);
  }
  @include mq(sp) {
    margin-top: 24px;
  }
}
.home_news .news_list .post:first-child {
  padding-top: 0;
}