@use '../abstracts' as *;

/* contact
********************************************** */
.contact .cta_tel {
  position: relative;
  z-index: 10;
  width: 100%;
  max-width: 320px;
  @include auto-margin;
  background-color: #000;
  margin-bottom: -32px;
  @include mq(sp) {
    max-width: 240px;
  }
}
.contact .cta_tel a {
  padding: 16px 24px;
  border: solid 1px;
  color: #fff;
  .contact .cta_tel a {
    display: block;
    padding: 16px;
  }
}
.contact .cta_tel svg {
  fill: #fff;
  @include mq(sp) {
    width: 20px;
  }
}
.contact .cta_tel .num {
  @include mq(sp) {
    font-size: 1.5rem;
  }
}