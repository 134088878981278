@use '../abstracts' as * ;

/* ##############################################################################

    FOOTER

############################################################################## */

/* cta
********************************************** */
  .cta {
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #E8ECF2;
    @include mq(1500) {
      background-position: center left 40%;
    }
    @include mq(sp, min) {
      min-height: 780px;
      background-image: url(../images/common/contact-img01.jpg);
    }
    @include mq(sp) {
      padding-bottom: 70vw;
      background-image: url(../images/common/contact-img01-sp.jpg);
      background-position: center bottom;
    }
  }
  .cta .txtarea {
    width: 50%;
    padding: 120px vw(190);
    margin-left: auto;
    margin-right: 0;
    @include mq(1500) {
      padding: 120px vw(40) 120px vw(80);
    }
    @include mq(sp) {
      width: 100%;
      padding: 48px 7%;
    }
  }
  .cta .txtarea p {
    margin-bottom: rem(28);
    @include mq(sp) {
      margin-bottom: rem(18);
      br {
        display: none;
      }
    }
  
    &:last-of-type {
      margin-bottom: rem(48);
      @include mq(sp) {
        margin-bottom: rem(24);
      }
    }
  }
  .footer--contact {
    @include mq(sp, min) {
      margin-right: 2rem;
    }
    @include mq(sp) {
      width: 100%;
      order: 2;
    }
  }
  .footer--contact a {
    color: var(--btn-clr-main-hvr, #fff);
    background-color: var(--btn-bg-main-hvr, #015691);
    @include mq(sp) {
      width: 100%;
    }

    svg {
      fill: var(--btn-clr-main-hvr, #fff);
    }

    &:hover {
      color: var(--btn-clr-main, #fff);
      background-color: var(--btn-bg-main, #015691);
      svg {
        fill: var(--btn-clr-main, #fff);
      }
    }
  }
  .footer--tel {
    @include mq(sp) {
      width: 100%;
      margin-bottom: 1rem;

      .num {
        font-size: rem(28) !important;
      }
    }
  }
  .footer--contact a {
    @include mq(sp) {
      padding-top: 10px !important;
      padding-bottom: 10px !important;
      font-size: rem(16);
    }
  }

/* footer_about
********************************************** */
  .footer {
    padding-top: 64px;
    padding-bottom: 12px;
    @include mq(sp) {
      padding-top: 40px;
    }
  }
  .footer_about {
    margin-bottom: rem(48);
    @include mq(sp) {
      margin-bottom: rem(28);
    }
  }
  .footer--logo {
    margin-right: rem(28);
    transform: translateY(12px);
    @include mq(sp) {
      margin-right: rem(18);
      transform: translateY(6px);
    }

    img {
      width: 160px;
      @include mq(sp) {
        width: 120px;
      }
    }
  }

  .sns--list li:not(:last-child) {
    margin-right: 1rem;
  }
  .sns--list a {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .sns--list a:hover {
    opacity: .7;
  }

  .footer--name {
    margin-top: rem(32);
  }
  .footer--name,
  .footer--txt {
    font-size: rem(15);
    line-height: 2;
  }


/* fnav
********************************************** */
  .fnav--menu {
    column-count: 2;
    gap: 0 64px;
    @include mq(sp) {
      gap: 0 32px;
      margin-bottom: rem(32);
    }
    @include mq(400) {
      font-size: rem(13);
    }
  }
  .fnav--menu a {
    display: block;
    padding-bottom: .25em;

    &:hover {
      opacity: .7;
      text-decoration: underline;
    }

    @include mq(sp) {
      position: relative;
      padding: .5em .5em .5em 0;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 3px;
        display: inline-block;
        margin: auto;
        width: 8px;
        height: 8px;
        border-top: 1px solid;
        border-right: 1px solid;
        transform: rotate(45deg);
      }
    }
  }

  .pbl a {
    opacity: .5;
    &:hover {
      opacity: 1;
    }
  }

  @include mq(sp, min) {
    .copyright {
      margin-right: 1em;
    }
  }