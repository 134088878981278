@use '../abstracts' as *;

/* hero
********************************************** */
.hero {
  position: relative;
}

.hero--movie {
  position: relative;
  height: 960px;
  overflow: hidden;
  @include mq(sp) {
    height: 480px;
  }

  .bg {
    position: absolute;
    inset: 0;
    z-index: -2;
    display: block;
    background-color: rgba(0, 47, 86, .95);
  }

  video {
    position: absolute;
    inset: -200%;
    z-index: -1;
    margin: auto;
    mix-blend-mode: multiply;
    @include mq(1920, min) {
      width: 100%;
    }
  }
}
.hero--movie .deco-1 {
  top: 32px;
  right: 0;
  max-width: vw(1144);
}
.hero--movie .deco-2 {
  inset: 0;
  top: auto;
}

.hero--catch {
  position: absolute;
  top: 4%;
  left: vw(230);
  bottom: 0;
  display: flex;
  @include center-flex;
  @include mq(sp) {
    top: 20%;
    left: 10%;
    right: 10%;
    bottom: auto;
  }
}
.hero--catch .ttl {
  position: relative;
  display: inline-block;
  margin-bottom: rem(48);
  font-size: rem(64);
  @include lh(64, 110);
  letter-spacing: .16em;
  font-feature-settings: normal;
  @include mq(sp) {
    margin-bottom: rem(24);
    font-size: rem(32);
  }
}
.hero--catch .ttl .en,
.hero--cta .en,
.home_feature .btn .en,
.products-content .en {
  display: inline-block;
  font-size: rem(12);
  font-weight: 500;
  letter-spacing: .05em;
  @include lh(12, 20);
  @include mq(sp) {
    font-size: rem(10);
  }
}
.hero--catch .ttl .en {
  position: absolute;
  right: 0;
  bottom: 2em;
  transform: translateX(110%);

  @include mq(sp) {
    bottom: 1em;
  }
}
.hero--catch .txt {
  @include mq(sp) {
    font-size: rem(12);

    br {
      display: none;
    }
  }
}

.hero--cta {
  position: absolute;
  right: vw(76);
  bottom: 100px;
  @include mq(sp) {
    right: 5%;
    bottom: 60px;
  }
}
.hero--cta .link {
  display: block;
  &:hover {
    transform: translateX(.25rem);
  }
}
.hero--cta .txt {
  display: flex;
  @include center-flex;
  font-size: rem(36);
  letter-spacing: .05em;
  @include mq(sp) {
    font-size: rem(18);
  }

  &::after {
    content: '';
    display: block;
    @include rect(80);
    margin-left: rem(40);
    border: solid 2px #000;
    border-radius: 50%;
    background-image: url(../images/common/arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 45% auto;
    filter: invert(100%);
    transition: var(--transit-default, all .4s ease-out);
    @include mq(sp) {
      @include rect(40);
      border-width: 1px;
      margin-left: rem(20);
    }
  }
}
.hero--cta .en {
  margin-left: 0;
  margin-bottom: 0;
  @include mq(sp) {
    display: none;
  }
}

.hero--sns {
  position: absolute;
  left: vw(64);
  bottom: 96px;
  @include mq(sp) {
    display: none;
  }
}
.hero--sns li {
  margin-top: rem(12);
}
.hero--sns svg {
  width: 16px;
  height: 16px;
  fill: var(--clr-blue); 
}


/* hero--news
********************************************** */
.hero--news {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  transform: translateY(50%);
  width: 54.8%;
  padding: 0;
  padding-left: vw(124);
  background-color: #fff;
  font-size: rem(14);
  box-shadow: -5px 5px 30px rgba(0,0,0,0.1);
  @include mq(1400) {
    padding-left: 2%;
  }
  @include mq(sp) {
    width: 90%;
    left: 5%;
    padding-left: 5%;
  }
}
.hero--news .ttl { 
  display: flex;
  @include center-flex;
  letter-spacing: .01em;
  margin-right: vw(52);
  @include mq(1400) {
    margin-right: 4%;
  }
  @include mq(sp) {
    display: none;
  }
}
.hero--news .news_list {
  flex: 1;
}
.hero--news .news_list .post {
  padding: 16px 0;
  border-bottom: none;
  @include mq(sp) {
    padding: 10px 0;
  }
}
.hero--news .news_list .cat_list {
  @include mq(1400) {
    display: none;
  }
}
.hero--news .news_list .post--ttl {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  font-size: rem(14);
}
.hero--news .news_list .post--txt {
  display: none;
}
.hero--news .more {
  display: flex;
  @include center-flex;
  width: vw(174);
  margin-left: vw(52);
  font-size: rem(13);
  text-align: center;
  border-left: solid 1px var(--btn-clr-main, #fff);
  color: var(--btn-clr-main-hvr, #fff);
  background-color: var(--btn-bg-main-hvr, #015691);
  @include mq(sp) {
    width: 64px;
    margin-left: 5%;
  }

  &:hover {
    color: var(--btn-clr-main, #fff);
    background-color: var(--btn-bg-main, #1D7FB7);
  }
}