@use '../abstracts' as *;

/* ##############################################################################

    ARCHIVE

############################################################################## */

/* main_column
**************************************** */
.main_column {
  flex: 1;
  margin-right: 80px;
  overflow: hidden;
}
.archive-product .main_column {
  margin-right: 0;
  margin-top: 40px;
}

/* --- post --- */
.post {
  position: relative;
}
.post--link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.post--date {
  color: #BFBFBF;
  font-size: rem(13);
  letter-spacing: .1em;
  white-space: nowrap;
}
.post--info.flex .post--date + .cat_list {
  margin-left: auto;
}
.cat_list {
  position: relative;
  z-index: 1;
  overflow: hidden;
} 
.cat_list a {
  display: inline-block;
  vertical-align: middle;
  font-size: rem(13);
  border: solid 1px;
  padding: 0.125em 1em;
  line-height: 1.5;
  float: left;
  white-space: nowrap;
  margin: 2px;
  @include mq(sp) {
    font-size: rem(10);
  }
}
.cat_list a:hover {
  opacity: 0.7;
}
.post--img {
  background-color: #eee;
}

@include mq(1100) {
  .container > .inner.flex {
    display: block;
  }
  .main_column {
    width: 100%;
    margin-bottom: 40px;
    margin-right: 0;
  }
  .archive-product .main_column {
    margin-top: 40px;
    margin-bottom: 0;
  }
}

/* side_column
**************************************** */
.side_column {
  width: 280px;
  padding: rem(48) rem(36);
  background-color: var(--bg-off_wht, #f7f7f7);
  @include mq(1100) {
    width: 100%;
  }
}
.archive-product .side_column {
  width: 100%;
  padding: 0;
  background-color: transparent;
}
.side_section:not(:last-child) {
  padding-bottom: rem(40);
  margin-bottom: rem(48);
  border-bottom: solid 1px;
}
.side--ttl {
  font-size: rem(20);
  letter-spacing: .05em;
  line-height: 1;
  margin-bottom: rem(20);
}
.side--ttl .en {
  display: block;
  font-size: rem(12);
  color: var(--clr-blue);
  letter-spacing: .05em;
  line-height: 1;
  font-weight: 500;
  margin-top: rem(12);
}

/* --- list --- */
.side--list li {
  &:not(:last-child) {
    margin-bottom: rem(4);
  }
}

.side--list a {
  position: relative;
  display: block;
  margin-left: 1em;

  &:before {
    content: "";
    position: absolute;
    top: .7em;
    left: -1em;
    width: .5em;
    height: .5em;
    border: 1px solid var(--clr-blk, #1D7FB7);
    background-color: var(--clr-blk, #1D7FB7);
    border-radius: 50%;
  }

  &:hover {
    opacity: .7;
  }
}
.side--btn li {
  @include mq(767) {
    margin-bottom: 3% !important;
  }
}

/* --- post --- */
.posts-side .post:not(:last-child) {
  margin-bottom: 16px;
}
.posts-side .post--img {
  margin-right: 16px;
  width: 64px;
  height: 64px;
}
.posts-side .txtarea {
  flex: 1;
}
.posts-side .post--date {
  margin-bottom: 0;
}
.posts-side .post--ttl {
  line-height: 1.4;
}

/* --- archive --- */
.side_column .archive_list--ttl {
  cursor: pointer;
  font-weight: 500;
  line-height: 1.8;
}
.side_column .archive_list--ttl::after {
  content: "";
  display: block;
  width: 8px;
  height: 8px;
  border-top: 1px solid;
  border-right: 1px solid;
  transform: rotate(135deg);
  transition: var(--trans-default, all 0.4s ease-out);
}
.side_column .archive_list--ttl.active::after {
  transform: rotate(315deg);
}
.side_column .archive_month {
  display: none;
}

/* --- archive-pulldown --- */
.archive-pulldown {
  position: relative;
  margin-left: auto;
  z-index: 1;
}
.archive-pulldown .archive_list {
  position: relative;
}
.archive-pulldown .archive_list:not(:last-child) {
  margin-right: 24px;
}
.archive-pulldown .archive_list a {
  display: block;
  padding: 0.25em 1em;
  text-align: left;
}
.archive-pulldown .archive_list a:not(:last-child) {
  border-bottom: 1px solid #eee;
}
.archive-pulldown .archive_list a:hover {
  background-color: #eee;
}
.archive-pulldown .archive_list--label {
  margin-right: 8px;
}
.archive-pulldown .archive_list--btn {
  cursor: pointer;
  border: none;
  outline: none;
  appearance: none;
  padding: 0.25em 4em 0.25em 2em;
  background-color: #fff;
  border: 1px solid #ccc;
  font-family: var(--font-primary, "YuGothic", "游ゴシック", "ヒラギノ角ゴ Pro W3",
    "Hiragino Kaku Gothic ProN", sans-serif);
  font-size: 1rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}
.archive-pulldown .archive_list--btn::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0.4em;
  right: 0.8em;
  margin: auto;
  width: 0.6em;
  height: 0.6em;
  border-bottom: 2px solid;
  border-right: 2px solid;
  transform: rotate(45deg);
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--btn::after {
  bottom: -0.2em;
  transform: rotate(225deg);
}
.archive-pulldown .archive_list--menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  visibility: hidden;
  margin-top: -1px;
  opacity: 0;
  transition: all 0.2s ease-out;
}
.archive-pulldown .active .archive_list--menu {
  visibility: visible;
  opacity: 1;
}


/* blog
**************************************** */
.posts-blog .post {
  padding-bottom: 48px;
  padding-top: 48px;
  border-bottom: 1px solid #e5e5e5;
}
.posts-blog .post:first-child {
  border-top: 1px solid #e5e5e5;
}
.posts-blog .post--txtarea {
  flex: 1;
  word-break: break-word;
}
.posts-blog .post--img {
  width: 280px;
  margin-right: 40px;
}
.posts-blog .post--img::before {
  padding-top: 75%;
}

@include mq(sp) {  
  .posts-blog .post {
    display: block;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .posts-blog .post--img {
    padding-left: 0;
    @include auto-margin;
    margin-bottom: 16px;
    text-align: center;
  }
  .posts-blog .post--img img {
    width: auto;
    max-width: 100%;
  }
}


/* case
**************************************** */
.posts-case .post--txtarea,
.posts-product .post--txtarea {
  word-break: break-word;
}
.posts-case .post--img,
.posts-product .post--img {
  margin-bottom: 18px;
  aspect-ratio: 4/3;
  @include mq(sp) {  
    margin-bottom: 12px;
  }
}
.posts-case .post--ttl,
.posts-product .post--ttl {
  margin-bottom: 12px;
  font-size: rem(20);
  line-height: 1.6;
  letter-spacing: .05em;
  @include mq(sp) { 
    margin-bottom: 6px;
    font-size: rem(18); 
    line-height: 1.4;
  }
}
.posts-case .post--txt,
.posts-product .post--txt {
  line-height: 1.7;
  @include mq(sp) { 
    line-height: 1.5;
  }
}
.posts-case .cat_list,
.posts-product .cat_list {
  margin-top: 24px;
  @include mq(sp) {  
    margin-top: 12px;
  }
}


/* product
**************************************** */
.posts-product .post--txt {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}


/* news
**************************************** */

/* --- news--archive --- */
.news--archive {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #111;
}
.news--archive li {
  @include auto-margin(8);
}
.news--archive a {
  color: #fff;
}

/* --- news_list --- */
.news_list .post {
  padding: 20px 1.5rem 18px;
  margin-bottom: 0;
  @include mq(sp) {
    display: block;
    padding: 14px 0;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #BFBFBF;
  }
}
.news_list .post--info {
  margin-right: 1.5rem;
  @include mq(sp) {
    margin-bottom: 4px;
    margin-right: 0;
  }
}
.news_list .post--ttl {
  font-size: rem(18);
  letter-spacing: .1em;
  @include mq(sp) {
    font-size: rem(16);
  }
}
.news_list .post--txt {
  font-size: rem(14);
  letter-spacing: .05em;
  color: #A1A1A1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  @include mq(sp) {
    font-size: rem(12);
  }
}
.news_list .post--link:not([href="javascript:void(0);"]):hover {
  color: var(--clr-main, #1d7fb7);
}
.news_list .post--link:hover svg {
  fill: var(--clr-main, #1d7fb7);
}
.news_list .post--info.flex .post--date + .cat_list {
  margin-left: 1.5rem;
  @include mq(sp) {
    margin-left: 1rem;
  }
}